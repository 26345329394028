<template>
  <div
    class="
        v-main-page
        page-container
    "
    id="page"
  >
    <img class="page-image" src="@/images/for-pages/main.jpg"/>
    <div class="zagolovok-container">
      <img src="@/images/for-pages/banya-logo.png" id="berloga" width="100px"/>
      <div class="zagolovok">Банный комплекс "Берлога"</div>
    </div>
    <div class="text-background">
      <div class="text" style="padding-top: 10px;">
        Коллектив Банного комплекса Берлога приглашает всех любителей легкого пара и хорошего настроения в гости.  Вас удивят доступные цены на качественные услуги, сочетание современного интерьера и безмятежной атмосферы, вкусные блюда и охладительные напитки в нашем банном комплексе не оставят равнодушными даже гурманов. Гостям предлагают два отделения: мужское и женское, русскую баню с купелью, турецкий хамам с мыльным массажем, большой бассейн и купель с ледяной водой. Мы предлагаем своим гостям широкий спектр SPA-процедур, а также услуги профессиональных банщиков, чтобы вы смогли забыть об усталости и пополнить запас жизненных сил.
      </div>
      <div class="text" style="padding-top: 10px;">
        Вы проведете свой досуг с комфортом и удобством в нашем банном комплексе!
      </div>
    </div>
    <div class="zagolovok-container">
      <div class="zagolovok">3D тур</div>
    </div>
    <a href="https://www.provisa.su/wp-admin/images/flash2/Berloga.html">
      <img class="page-image" style="padding-top: 20px;" src="@/images/for-pages/tour.jpg"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'v-main-page',
  components: {
  }
}
</script>

<style>
</style>