<template>
  <div
    class="
      v-contacts-page
      page-container
    "
    id="page"
  >
    contacts page
  </div>
</template>

<script>
export default {
  name: 'v-contacts-page',
  components: {
  }
}
</script>

<style>
</style>