<template>
  <div
    class="
      v-female-page
      page-container
    "
    id="page"
  >
    <div class="zagolovok-container">
      <div class="zagolovok">Женское отделение</div>
    </div>
    <div class="text-background">
      <div class="text" style="padding-top: 10px;">
        Женское отделение банного комплекса Берлога рассчитано на одновременное посещение 40 человек. Каждому гостю предоставляется отдельный шкафчик с электронным замком, номер на шкафчике соответствует посадочному месту. В моечном отделении находится бассейн размерами 6*5 м., глубина 1,5-1,9 м., температура воды 28 градусов. Русская парная, рассчитана на 30 человек. Температура в парной 85-95 градусов. Объём парной 70 куб.м, Топится 1-й газовой печью. Турецкий хамам, в котором могут комфортно расположиться до 10 человек. Для клиентов бани работает кухня и бар.
      </div>
    </div>
    <div class="cards" style="margin-bottom: 20px;">
      <div class="male-time-card">
        <FemaleTime class="MaleTime"/>
      </div>
      <div class="male-time-card dop">
        <div class="discont">Дети до 7 лет бесплатно</div>
        <div class="discont">Дети с 7 до 12 лет - 600 руб/2часа</div>
        <div class="discont"><b>Социальные дни посещения:</b> вторник, среда.</div>
        <div class="discont">Тариф с 11:00 до 17:00 - 350 руб/2 часа</div>
        <div class="discont"><b>Категории граждан:</b></div>
        <div class="discont">- Пенсионеры по возрасту (женщины 55 лет, мужчины 60 лет)</div>
        <div class="discont">- Инвалиды 1 и 2 группы</div>
        <div class="discont">- Участники боевых действий</div>
        <div class="discont">С предоставлением соответствующих документов</div>
      </div>
    </div>
    <swiper
      :spaceBetween="0"
      :centeredSlides="true"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
      }"
      :navigation="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide><img src="@/images/for-pages/female/20.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/female/21.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/female/22.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/female/23.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/female/24.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/female/25.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/female/26.jpg" class="page-image"></swiper-slide>
    </swiper>
  </div>
</template>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/pagination';
  import 'swiper/css/navigation';


  // import required modules
  import { Autoplay, Pagination, Navigation } from 'swiper/modules';
    import FemaleTime from '@/components/v-female-time.vue'

  export default {
    name: 'v-ауmale-page',
    components: {
      Swiper,
      SwiperSlide,
      FemaleTime
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
  };
</script>

<style>
</style>