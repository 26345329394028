<template>
  <div
    class="
      v-male-page
      page-container
    "
    id="page"
  >
    <div class="zagolovok-container">
      <div class="zagolovok">Мужское отделение</div>
    </div>
    <div class="text-background">
      <div class="text" style="padding-top: 10px;">
        Мужское отделение банного комплекса Берлога рассчитано на одновременное посещение 70 человек. Каждому гостю предоставляется отдельный шкафчик с электронным замком, номер на шкафчике соответствует посадочному месту. В моечном отделении находится бассейн размерами 6*5 м., глубина 1,5-1,9 м., температура воды 28 градусов. Русская парная рассчитана на 40 человек. Температура в парной 90-100 градусов. Объём парной 100 м.куб. Топится 2-мя газовыми печами (Тепло дар). Турецкий хамам, в котором могут комфортно расположиться до 10 человек. Для любителей экстрима есть купель с температурой воды 12 градусов. Для клиентов бани работает кухня и бар. Так же есть отдельная зона для курения.
      </div>
    </div>
    <div class="cards" style="margin-bottom: 20px;">
      <div class="male-time-card">
        <MaleTime class="MaleTime"/>
      </div>
      <div class="male-time-card dop">
        <div class="discont">Дети до 7 лет бесплатно</div>
        <div class="discont">Дети с 7 до 12 лет - 600 руб/2часа</div>
        <div class="discont"><b>Социальные дни посещения:</b> вторник, среда.</div>
        <div class="discont">Тариф с 11:00 до 17:00 - 350 руб/2 часа</div>
        <div class="discont"><b>Категории граждан:</b></div>
        <div class="discont">- Пенсионеры по возрасту (женщины 55 лет, мужчины 60 лет)</div>
        <div class="discont">- Инвалиды 1 и 2 группы</div>
        <div class="discont">- Участники боевых действий</div>
        <div class="discont">С предоставлением соответствующих документов</div>
      </div>
    </div>
    <swiper
      :spaceBetween="0"
      :centeredSlides="true"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
      }"
      :navigation="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide><img src="@/images/for-pages/male/7.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/8.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/9.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/10.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/11.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/12.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/13.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/14.jpg" class="page-image"></swiper-slide>
      <swiper-slide><img src="@/images/for-pages/male/15.jpg" class="page-image"></swiper-slide>
    </swiper>
  </div>
</template>

<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';

  import 'swiper/css/pagination';
  import 'swiper/css/navigation';


  // import required modules
  import { Autoplay, Pagination, Navigation } from 'swiper/modules';
  import MaleTime from '@/components/v-male-time.vue'

  export default {
    name: 'v-male-page',
    components: {
      Swiper,
      SwiperSlide,
      MaleTime
    },
    setup() {
      return {
        modules: [Autoplay, Pagination, Navigation],
      };
    },
  };
</script>

<style>
.discont{
  font-size: 18px;
  margin-bottom: 5px;
  text-align: left;
}
.dop{
  margin-top: 0px;
  top: 10%;
  left: 20%;
  transform: translate(23%, -4%);
  margin-bottom: 0;
}
.cards{
  width: 100%;
}
.MaleTime{
  width:550px;
  height: 230px;
  background-color:#f5f8fc;
  border-radius: 20px;
  border: 1px solid #121820
}
.male-time-card{
  display: inline-block;
  width: 45%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.time-card-header{
  margin-top: 30px;
  text-align: center;
      font-size: 40px;
  margin-bottom:20px;
      margin-left: 40px;
  left: 20%;
}
@media screen and (max-width: 700px) {
  .dop {
      text-align: center;
      left: 0px;
      transform: translate(0%, 0%);
  }
  .discont{
    width: 80%;
    text-align: center;
    padding-left: 40px;
  }
  .MaleTime{
    width:300px;
    height: 230px;
    background-color:#f5f8fc;
    border-radius: 20px;
    border: 1px solid #121820
  }

  .male-time-card{
    display: block;
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }

  .time-card-header{
    margin-left: 0px;
  }
}

@media screen and (min-width: 701px) and  (max-width: 1100px){
  .dop {
      top: 20%;
      left: 10%;
      transform: translate(10%, 0%);
  }
  .discont{
    width: 80%;
    text-align: center;
  }
  .male-time-card{
    display: block;
    width: 100%;
  }
  .time-card-header{
    margin-left: 0px;
  }
  .MaleTime{
    width:300px;
    height: 230px;
    background-color:#f5f8fc;
    border-radius: 20px;
    border: 1px solid #121820
  }
}
</style>